<template>
	<div class="invoice_content">
		<div class="invoice_con">
			<div class="invoice_box" v-for="(item,index) in InvoiceOrderInfo" :key="index">
					<div class="left">
						<div class="con">
							<div class="con_desc">
								<span class="con_packageName">
									{{ item.packageName }}
								</span>
							</div>
							<div class="con_date">{{ item.createTime }}</div>
						</div>
					</div>
					<div class="right">
						<div style="display:flex;">
							<div class="desc_reward">{{ item.orderAmount }}￥</div>
						</div>
						<div class="desc_date">
							{{
								item.beginTime
									? item.beginTime.slice(0, 10) + "至"
									: ""
							}}{{ item.endTime ? item.endTime.slice(0, 10) : "" }}
						</div>
					</div>
			</div>
		</div>
		<div class="Invoice-button">
			<van-button class="Invoice" size="large" round @click="returnHandle">返回</van-button>
		</div>
	</div>
</template>

<script>
// 需要哪些模块按需引入
import { onMounted, reactive, toRefs, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
export default {
  setup() {
    const state = reactive({
			InvoiceOrderInfo:[]
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router, $http: http } = ctx;
		//调用useRoute,获取页面携带的参数
    const route = useRoute();
    const methods = {
      // 返回
      returnHandle() {
        window.history.go(-1)
      },
			// 获取发票所含订单详情
			getOrderInfo(){
				http.get(`/invoice/orderInfo`,{
					params:{
						invoiceId:route.query.id
					}
				}).then(({data : res})=>{
					if(res.code == 0){
						state.InvoiceOrderInfo = res.data
						return 
					}
					tool.toast({
            msg: res.msg,
            duration: 1500
          });
				})
			}
    };
    onMounted(() => {
			ctx.getOrderInfo()
		});
    return { ...toRefs(state), ...methods };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.invoice_content{
	.invoice_con {
		background: #ffffff;
		border-radius: 0px 0px 20px 20px;
		margin: 0 auto;
		padding: 16px 30px 0;
		width: 630px;
		height: calc(60vh - 118px);
		overflow-y: auto;
	}
	.invoice_box {
		display: flex;
		justify-content: space-between;
		border-bottom-color: aliceblue;
		border-bottom-width: 1px;
		border-bottom-style: solid;
		.left {
			display: flex;
			div img {
				width: 48px;
				height: 48px;
			}
			.con {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding-left: 10px;
				.con_desc {
					font: {
						size: 30px;
						weight: 400;
					}
					color: #333333;
					.con_packageName {
						width: 300px;
						white-space: nowrap;
						overflow-x: auto;
						display: inline-block;
						position: relative;
						top: 8px;
					}
				}
				.con_date {
					font: {
						size: 22px;
						weight: 400;
					}
					color: #aaaaaa;
					padding: 10px 0px;
				}
			}
		}
		.right {
			padding: 10px 8px 0px 8px;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			.desc_reward {
				font-size: 36px;
				color: #fc992c;
			}
			.desc_date {
				width: 270px;
				white-space: nowrap;
				overflow-x: auto;
				font: {
					size: 22px;
					weight: 400;
				}
				color: #aaaaaa;
			}
		}
	}
	.Invoice-button{
		width: 690px;
		margin: 30px auto;
	}
	.Invoice{
		color: #fff;
		width: 100%;
		@include background_color("background_color1");
	}
}
</style>
